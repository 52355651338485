import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const FotovoltaicoAccumulo = () => (
  <Layout isPagina={true}>
    <SEO
      title="Fotovoltaico con batterie di accumulo Roma, Latina, Aprilia, Pomezia, Ardea, Anzio, Nettuno - NSE srl - Soluzioni Integrate di Ingegneria Energetica"
      description="Pannelli solari e Fotovoltaico con batteria di accumulo in tutto il Lazio"
    />
    <BanneronePage title="Fotovoltaico con Accumulo" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Fotovoltaico con batterie di accumulo Roma, Latina, Aprilia, Pomezia, Ardea, Anzio, Nettuno" />
      <CardPagina variante="bordoTop text-center">
        <span className="gridParity">
          Impianti fotovoltaici Lazio con sistemi intelligenti di accumulo con
          batterie ad elevata efficienza e durata: per impianti nuovi ed
          impianti esistenti (retrofit)
          <br />
          <br />
          <strong>
            Autoconsumo fino al 100% di autonomia dal fornitore elettrico
          </strong>
        </span>
      </CardPagina>
      <p className="text-justify my-5 mx-5">
        <span className="text-center">
          <b>
            Immagazzinare e consumare l’energia prodotta dall’impianto
            fotovoltaico conviene!!
          </b>
          <br />
        </span>
        <span>
          Consumare l’energia accumulata presenta un chiaro vantaggio economico.
          Minore è l’elettricità prelevata dalla rete, maggiore sarà il grado di
          autosufficienza energetica, con conseguente riduzione dei costi in
          bolletta sia per utenze domestiche che aziendali (con turni
          serali/notturni). I sistemi di accumulo di NSE srl garantiscono
          l'autonomia dalla rete ed in caso di necessità il riallaccio
          automatico senza interruzioni di fornitura (funzione EPS=Emergency
          Power Supply in caso di blackout).
          <br />
          <br />
          Grazie ai sistemi di accumulo di NSE srl, TESLA, SOLAREDGE, ZCS, HUAWEI, Pyolontech, etc., l'energia prodotta
          dall'impianto fotovoltaico, finora immessa in rete, viene gestita in
          maniera ottimizzata, permettendo di utilizzare la rete elettrica di
          fornitura solamente come backup nel caso di assenza di irraggiamento
          solare e batterie scariche.
        </span>
        <br />
      </p>
      <Row>
        <Col sm={6}>
          <img
            className="img-fluid mx-auto"
            src="../Accumulo-FV.png"
            alt="Offerta impianto Fotovoltaico lazio"
          />
        </Col>
        <Col sm={6}>
          <img
            className="img-fluid mx-auto"
            src="../graficoautoconsumo.jpg"
            alt="impianto con accumulo grafico autoconsumo lazio"
          />
        </Col>
      </Row>
      <CardPagina variante="bordoTop gridParity text-center">
        Impianti fotovoltaici Lazio con sistemi di accumulo: autoconsumo fino al
        100% - autonomia dalla rete
        <br />
        <BottoneBianco
          className="mt-3 text-center variantePage"
          title="Richiedi un preventivo"
          link="richiesta-preventivo"
        />
      </CardPagina>
      <Sezione title="Impianto esistente o nuovo?">
        <Tabs
          className="text-center mx-auto nav-justified"
          defaultActiveKey="servizi"
          id="uncontrolled-tab-example"
        >
          <Tab
            eventKey="servizi"
            title="Accumulo impianti fotovoltaici esistenti"
          >
            <div className="m-5 text-justify">
              Le soluzioni di NSE srl per impianti residenziali esistenti sono
              costituite da sistemi di accumulo di energia con batterie solari
              ad alta efficienza agli ioni di Litio Ferro Fosfato e da un quadro
              elettrico completo di inverter e microprocessori per la gestione
              dell'energia, associabile a qualsiasi tipologia di impianto
              fotovoltaico, anche già in servizio. La capacità di stoccaggio
              corrisponde al fabbisogno energetico medio giornaliero di una
              famiglia di 4-6 persone e rapprenta il traguardo della completa
              autonomia energetica. Il sistema, decide autonomamente se
              immagazzinare, immettere in rete domestica o commutare sulla rete
              pubblica l'energia prodotta dall'impianto fotovoltaico in
              conformità alla normativa CEI 0-21. <br />
              <br /> Il sistema non immette mai in rete l'energia presente
              nell'accumulo grazie alla scheda elettronica certificata CEI EN
              62040-1 in conformità alla normativa vigente.
              <div className="text-center">
                <img
                  className="img-fluid mt-4 mx-auto"
                  src="../accumulo-esistenti.png"
                  alt="impianto con accumulo grafico autoconsumo lazio"
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="prodotti" title="Accumulo nuovi impianti fotovoltaici">
            <div className="m-5 text-justify">
              I sistemi di NSE srl per nuovi impianti, sono costituiti da un
              sistema di accumulo di energia con batterie solari ad alta
              efficienza LiFePO4 con certificato di garanzia di 10 anni e da una centralina smart con quadro
              elettrico completo per la gestione ottimizzata delle batterie.
              L'unità di comunicazione, alloggiata nel quadro elettrico, decide
              autonomamente se immagazzinare, immettere in rete domestica o
              commutare sulla rete pubblica. <br />
              <br />
              Il sistema non immette mai in rete grazie alla scheda elettronica
              certificata CEI EN 62040-1, nel rispetto della normativa vigente.
            </div>
            <div className="text-center">
              <img
                className="img-fluid mt-4 mx-auto"
                src="../accumulo-nuovi.png"
                alt="impianto con accumulo lazio"
              />
            </div>
          </Tab>
        </Tabs>
      </Sezione>

      <CardPagina variante="bordoTop gridParity text-center">
        Richiedi un preventivo <strong>gratuito</strong> per un impianto
        fotovoltaico con accumulo o l'aggiornamento/potenziamento di un impianto fotovoltaico
        esistente
        <strong>
          rispondente alle tue esigenze di risparmio e investimento{" "}
        </strong>
        <br /> <br />
        DETRAZIONE IRPEF DEL 50% SULL'INTERO IMPORTO con tempi di rientro molto ridotti!
        <br />
        <br />
        <BottoneBianco
          className="mt-3 text-center variantePage"
          title="Richiedi un preventivo"
          link="richiesta-preventivo"
        />
      </CardPagina>
    </Container>
  </Layout>
)

export default FotovoltaicoAccumulo
